.home__services {
    width: 100%;
    height: 100vh;
    display: grid;
    gap: 25%;
    @media (min-width: $layout-breakpoint-small) {
        display: flex;
        gap: 0;
    }

    &-left {
        position: relative;
        height: 100%;
        width: 100%;
        background-image: url("../../../assets/Images/hero/services1.png");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        margin-bottom: $size6;

        @media (min-width: $layout-breakpoint-small) {
            display: flex;
            background-image: url("../../../assets/Images/hero/services1Big.png");
        }

        .services__card {
            display: grid;
            position: absolute;
            background: $white;
            border-radius: $borderRadius;
            right: 5%;
            top: 55%;
            -webkit-box-shadow: 30px 29px 18px -30px rgba(0, 0, 0, 0.75);
            -moz-box-shadow: 30px 29px 18px -30px rgba(0, 0, 0, 0.75);
            box-shadow: 30px 29px 18px -30px rgba(0, 0, 0, 0.75);

            &-header {
                display: flex;
                border-bottom: 1px solid $grey;
                margin: $size1 0;
                img {
                    margin: $size2;
                    @media (min-width: $layout-breakpoint-small) {
                        margin: $size4;
                        transform: scale(1.3);
                    }
                }
                div > * {
                    margin: $size1;
                    @media (min-width: $layout-breakpoint-small) {
                        margin: $size2;
                    }
                }
                h5 {
                    text-transform: uppercase;
                    padding: 4px $size1;
                    color: $green;
                    background-color: $lightGreen;
                    border-radius: $borderRadius;
                    text-align: center;
                }
            }
            &-content {
                ul {
                    padding: $size2;
                    @media (min-width: $layout-breakpoint-small) {
                        padding: $size5;
                    }
                    li {
                        display: flex;
                        margin-bottom: $size2;
                        justify-content: flex-start;
                        align-items: center;
                        @media (min-width: $layout-breakpoint-small) {
                            margin-bottom: $size4;
                        }
                        img {
                            margin-right: $size2;
                            @media (min-width: $layout-breakpoint-small) {
                                transform: scale(2);
                                margin-right: $size4;
                            }
                        }
                        div {
                            display: grid;
                        }
                        small {
                            padding-left: $size1;
                        }
                    }
                }
            }
        }
    }
    &-right {
        width: 100%;
        padding: $size2;
        h2 {
            padding: $size2;
        }
        @media (min-width: $layout-breakpoint-small) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }
        .services__slider {
            display: flex;
            flex-direction: column;
            padding: $size2;

            details {
                display: grid;
                place-items: center;
                summary {
                    height: 50px;
                }
                & > * {
                    padding: $size1 0;
                }
                P {
                    width: 100%;
                    @media (min-width: $layout-breakpoint-small) {
                        width: 80%;
                    }
                }
            }
        }
    }
}
