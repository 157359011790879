.header {
    width: 100%;
    position: fixed;
    padding: 0 0 $size1 $size1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 110;
    @media (min-width: $layout-breakpoint-small) {
        padding: $size4 0;
    }

    &__menu {
        position: relative;
        width: 100%;
        display: flex;

        .progress-bar {
            position: absolute;
            bottom: -12px;
            left: 0;
            right: 0;
            height: 5px;
            background: $green;
            transform-origin: 0%;
            transition: all 0.6s ease-in-out;
        }
    }
    .hamburger-menu {
        @media (min-width: $layout-breakpoint-small) {
            display: none;
        }
        button {
            border: none;
            outline: none;
            width: 60px;
            height: 60px;
            z-index: 100;
            background: none;
            cursor: pointer;
            span {
                width: 40px;
                height: 5px;
                display: block;
                border-radius: $borderRadius;
                margin: $size1;
                background: $white;
            }
        }
    }
}

.header.bg {
    background: $darkTurquoise;
    border-bottom: 1px solid $white;
    transition: all 0.6s ease-in-out;
    @media (min-width: $layout-breakpoint-small) {
        padding: $size2 0;
    }
}

.progress-bar {
    position: fixed;
    top: 65px;
    left: 0;
    right: 0;
    height: 5px;
    background: $green;
    transform-origin: 0%;
    @media (min-width: $layout-breakpoint-small) {
        top: 55px;
    }
  }
  
