.home {
    height: 90vh;
    width: 100%;
    background-image: url("../../../assets/Images/hero/home-hero.png");
    background-repeat: no-repeat;
    background-position: center right;
    background-color: $turquoise;

    @media (max-width: $layout-breakpoint-xsmall) {
        text-align: center;
        width: 100%;
    }

    &__hero {
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        color: $white;
        padding: $size2;
        @media (min-width: $layout-breakpoint-medium) {
            padding: 0;
        }
        @media (min-width: $layout-breakpoint-xsmall) {
            justify-content: center;
        }

        &-subtitle {
            display: flex;
            justify-content: center;
            align-items: center;
            h3 {
                width: 100%;
                @media (max-width: $layout-breakpoint-xsmall) {
                    line-height: 1.4;
                    text-align: center;
                    width: 60%;
                }
            }
        }

        h1 {
            width: 70%;
            @media (max-width: $layout-breakpoint-xsmall) {
                text-align: center;
                width: 100%;
            }
        }

        &-buttons {
            margin-top: $size4;
            width: 100%;
            display: grid;
            place-items: center;
            & > * {
                margin-top: $size1;
            }
            @media (min-width: $layout-breakpoint-xsmall) {
                display: flex;
                & > * {
                    margin-right: $size4;
                }
            }
        }
    }

    &__video {
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 1) 50%,
            rgba(62, 100, 94, 1) 50%,
            rgba(62, 100, 94, 1) 100%
        );
        padding: $size2;
        margin-bottom: $size5;
    }
}

.video-container {
    position: relative;
    padding-bottom: calc(var(--aspect-ratio, 0.565) * 70%);
    width: 70%;
    height: 0;

    @media (max-width: $layout-breakpoint-small) {
        padding-bottom: calc(var(--aspect-ratio, 0.5625) * 100%);
        width: 100%;
    }

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
