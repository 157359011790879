// Breakpoints
$layout-breakpoint-large: 1920px;
$layout-breakpoint-medium: 1440px;
$layout-breakpoint-small: 960px;
$layout-breakpoint-xsmall: 480px;

// Colors
$white: #fff;
$black: #24242a;
$red: #f94144;
$lightRed: #e4c9ba;
$orange: #f3722c;
$yellow: #f9c74f;
$turquoise: #3e645e;
$darkTurquoise: #16443c;
$blue: #277da1;
$green: #aec62e;
$lightGreen: #7cff028e;
$grey: #999c955b;
$borderRadius: 20px;
$size1: 8px;
$size2: 12px;
$size3: 16px;
$size4: 24px;
$size5: 36px;
$size6: 54px;
$size7: 72px;
