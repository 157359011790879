.info {
    background: $turquoise;
    height: 65vh;
    @media (min-width: $layout-breakpoint-small) {
        height: 60vh;
    }
    &__hero {
        padding: $size1;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: $white;
        @media (min-width: $layout-breakpoint-small) {
            padding: $size1 0;
        }
        & > * {
            padding: $size3 0;
        }
        p {
            text-align: left;
            line-height: 1.5;
            width: 80%;
            @media (min-width: $layout-breakpoint-small) {
                width: 70%;
            }
        }
    }
}
