.events {
    background: $turquoise;

    &__hero {
        display: grid;
        padding: $size6 $size1;
        width: 100%;
        @media (min-width: $layout-breakpoint-small) {
            padding: 10% 0;
        }
        &-title {
            margin: $size5 0;
            @media (min-width: $layout-breakpoint-small) {
                margin: $size5 0;
            }
            h1 {
                color: $white;
                width: 90%;
                @media (min-width: $layout-breakpoint-small) {
                    width: 60%;
                }
            }
            h3 {
                color: $white;
                width: 80%;
                font-size: $size3;
                margin: $size3 0;
                @media (min-width: $layout-breakpoint-small) {
                    width: 100%;
                    font-size: $size4;
                    margin: $size4 0 0 0;
                }
            }
        }
        &-search {
            width: 100%;
            input {
                width: 100%;
                padding: $size1;
                border-radius: 4px;
                border: none;
            }
        }
    }
}
