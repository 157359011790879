body {
    background: white;
}

.container {
    margin: 0 auto;
    max-width: 900px;
    @media (min-width: $layout-breakpoint-medium) {
        max-width: 1200px;
    }
}

.white-content {
    background: $black;
    color: $white;
}

.subtitle-line {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    height: 2px;
    width: 50px;
    margin-right: $size5;

    @media (max-width: $layout-breakpoint-xsmall) {
        display: none;
    }
}

.title-divider {
    position: relative;
    width: 100%;

    h2 {
        background-color: $white;
        height: 100%;
        z-index: 1;
        @media (max-width: $layout-breakpoint-small) {
            text-align: center;
        }
    }

    hr {
        position: absolute;
        top: 10px;
        right: 0;
        width: 85%;
        @media (min-width: $layout-breakpoint-small) and (max-width: $layout-breakpoint-medium) {
            width: 80%;
        }
        @media (max-width: $layout-breakpoint-small) {
            display: none;
        }
    }
}
