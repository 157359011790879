.home__info {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;

    @media (min-width: $layout-breakpoint-small) {
        display: flex;
        height: 100vh;
    }

    & > * {
        margin: $size1 0;
    }

    &-title {
        line-height: 1.4;
        text-align: center;
        width: 100%;
        @media (min-width: $layout-breakpoint-small) {
            width: 75%;
            text-align: left;
        }
    }

    &-cards {
        display: grid;
        place-items: center;
        margin: $size5 0;
        width: 100%;
        @media (min-width: $layout-breakpoint-small) {
            display: flex;
            justify-content: space-evenly;
        }
    }
    &-card {
        margin: $size5 0;
        display: grid;
        place-items: center;
        @media (min-width: $layout-breakpoint-small) {
            margin: 0;
        }

        img {
            width: 100%;
            @media (min-width: $layout-breakpoint-small) {
            }
        }
        figcaption {
            text-align: center;
            width: 80%;
            margin: $size4 0;
        }
    }
}
