.navigation {
    position: fixed;
    padding: 0 0 $size1 $size1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $red;
    z-index: 1000;
    overflow: hidden;
    .navigation-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .hamburger-navigation {
        display: grid;
        place-items: center;
        // @media (min-width:  $layout-breakpoint-xsmall) {
        //   display: none;
        // }
        button {
            border: none;
            outline: none;
            width: 60px;
            height: 60px;
            z-index: 100;
            background: none;
            cursor: pointer;
            span {
                width: 40px;
                height: 5px;
                display: block;
                border-radius: $borderRadius;
                margin: 8px;
                background: $white;
            }
        }
    }
}

// @keyframes animation {
//     from {
//         width: 0;
//     }

//     to {
//         width: 100%;
//     }
// }
