.search {
    @media (max-width: $layout-breakpoint-small) {
        display: none;
    }
}

.search input {
    background-color: white;
    width: 100%;
    padding: 4px $size1;
}

.searchInputs {
    position: relative;
}

input:focus {
    outline: none;
}

.dataResult {
    position: absolute;
    top: 55px;
    width: 170px;
    max-height: 150px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    overflow: hidden;
    overflow-y: auto;
}

.dataResult::-webkit-scrollbar {
    display: none;
}

.dataResult .dataItem {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    color: $black;
}

.dataItem h5 {
    margin-left: 10px;
}

.dataItem a:hover {
    background-color: lightgrey;
}
